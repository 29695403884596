import Register from "components/Authentification/modals/register";
import Header from 'components/Header/Header';
import { ArrowDown, Award, BarChart3, Bug, CheckCircle2, ChevronRight, Clock, Code2, FileCheck, GitBranch, HelpCircle, LineChart, MinusCircle, PlusCircle, Settings, Shield, TestTube2, Timer, UserCheck, Users, Workflow } from 'lucide-react';
import { useState } from "react";
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import FooterHome from '../FooterHome/FooterHome';
import Offers from "./Offres";
import { Helmet } from "react-helmet";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #0F0F1A;
    color: #ffffff;
    font-family: 'JetBrains Mono', monospace;
  }
`;

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(139, 92, 246, 0.4); }
  70% { box-shadow: 0 0 0 20px rgba(139, 92, 246, 0); }
  100% { box-shadow: 0 0 0 0 rgba(139, 92, 246, 0); }
`;

const float = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

const MainContainer = styled.div`
  min-height: 100vh;
  background: #0F0F1A;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 10% 10%, rgba(139, 92, 246, 0.1) 0%, transparent 50%),
      radial-gradient(circle at 90% 90%, rgba(79, 70, 229, 0.1) 0%, transparent 50%);
    pointer-events: none;
  }
`;

const HeroSection = styled.section`
  min-height: 67vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(139, 92, 246, 0.1), rgba(79, 70, 229, 0.1));
    z-index: 0;
  }
`;

const HeroContent = styled.div`
  max-width: 64rem;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
`;

const Logo = styled.div`
  width: 150px;
  height: 150px;
  margin: 0 auto 2rem;
  background: linear-gradient(135deg, #8B5CF6, #4F46E5);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${float} 6s ease-in-out infinite;
  box-shadow: 0 10px 30px rgba(139, 92, 246, 0.3);

  svg {
    width: 80px;
    height: 80px;
    color: #ffffff;
  }
`;

const HeroTitle = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  margin: 2rem 0;
  color: #ffffff;
  background: linear-gradient(to right, #8B5CF6, #4F46E5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.25rem;
  color: #A5B4FC;
  margin-bottom: 2rem;
  line-height: 1.6;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const Button = styled.button`
  background: linear-gradient(135deg, #8B5CF6, #4F46E5);
  color: white;
  padding: 1.25rem 2.5rem;
  border-radius: 12px;
  font-family: 'JetBrains Mono', monospace;
  font-weight: 600;
  font-size: 1.125rem;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 30px rgba(139, 92, 246, 0.4);
    animation: ${pulse} 2s infinite;
  }
`;

const Section = styled.section`
  padding: 8rem 2rem;
  position: relative;
  background: #1A1A2E;

  &:nth-child(odd) {
    background: #0F0F1A;
  }
`;

const SectionContent = styled.div`
  max-width: 72rem;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  color: #ffffff;
  background: linear-gradient(to right, #8B5CF6, #4F46E5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
    color: #8B5CF6;
  }
`;

const SectionSubtitle = styled.p`
  text-align: center;
  color: #A5B4FC;
  font-size: 1.25rem;
  margin-bottom: 4rem;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`;

const ServiceCard = styled.div`
  background: rgba(139, 92, 246, 0.05);
  border: 1px solid rgba(139, 92, 246, 0.1);
  border-radius: 20px;
  padding: 2.5rem;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(139, 92, 246, 0.2);
    border-color: #8B5CF6;
  }
`;

const ServiceIcon = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto 2rem;
  background: linear-gradient(135deg, #8B5CF6, #4F46E5);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 1rem;
  text-align: center;
`;

const ServiceDescription = styled.p`
  color: #A5B4FC;
  line-height: 1.6;
  text-align: center;
`;

const ServiceStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;
  padding: 1rem;
  background: rgba(139, 92, 246, 0.05);
  border-radius: 12px;
`;

const StatItem = styled.div`
  text-align: center;
  
  .value {
    color: #8B5CF6;
    font-weight: 600;
    font-size: 1.125rem;
  }
  
  .label {
    color: #A5B4FC;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
`;

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
`;

const ProjectCard = styled.div`
  background: rgba(139, 92, 246, 0.05);
  border: 1px solid rgba(139, 92, 246, 0.1);
  border-radius: 20px;
  padding: 2.5rem;
  transition: all 0.3s;

  &:hover {
    transform: translateX(10px);
    box-shadow: 0 20px 40px rgba(139, 92, 246, 0.2);
    border-color: #8B5CF6;
  }
`;

const ProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  @media (max-width: 640px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const ProjectInfo = styled.div`
  flex: 1;
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 0.5rem;
`;

const ProjectCompany = styled.p`
  color: #A5B4FC;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;

const ProjectMeta = styled.div`
  display: flex;
  gap: 1rem;
  color: #A5B4FC;
  font-size: 0.875rem;
`;

const ProjectMetrics = styled.div`
  background: linear-gradient(135deg, #8B5CF6, #4F46E5);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  font-weight: 600;
`;

const ProjectDescription = styled.p`
  color: #A5B4FC;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const TechStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const TechTag = styled.span`
  background: rgba(139, 92, 246, 0.1);
  color: #A5B4FC;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  border: 1px solid rgba(139, 92, 246, 0.2);
  transition: 0.3s;

  &:hover {
    background: rgba(139, 92, 246, 0.2);
    border-color: #8B5CF6;
  }
`;

const MetricsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
`;

const MetricCard = styled.div`
  background: rgba(139, 92, 246, 0.05);
  border: 1px solid rgba(139, 92, 246, 0.1);
  border-radius: 16px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-5px);
    border-color: #8B5CF6;
  }
`;

const MetricValue = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(to right, #8B5CF6, #4F46E5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
`;

const MetricLabel = styled.div`
  color: #A5B4FC;
  font-size: 1rem;
`;

const ClientLogos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin: 4rem 0;
  flex-wrap: wrap;
`;

const ClientLogo = styled.div`
  opacity: 0.6;
  transition: opacity 0.3s;
  filter: grayscale(100%);
  
  &:hover {
    opacity: 1;
    filter: none;
  }
`;

const MethodologySection = styled(Section)`
  background: #0F0F1A;
`;

const MethodologyGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
`;

const MethodologyCard = styled(ServiceCard)`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MethodologyIcon = styled(ServiceIcon)`
  margin: 0;
  width: 60px;
  height: 60px;
`;

const MethodologyTitle = styled(ServiceTitle)`
  text-align: left;
  margin: 0;
`;

const MethodologyDescription = styled(ServiceDescription)`
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4rem;
`;

const SeeMoreButton = styled(Button)`
  background: transparent;
  border: 2px solid #8B5CF6;
  color: #A5B4FC;
  
  &:hover {
    background: rgba(139, 92, 246, 0.1);
  }

  svg {
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: translateY(4px);
  }
`;

const TrustBadge = styled.div`
  background: rgba(139, 92, 246, 0.1);
  border: 1px solid rgba(139, 92, 246, 0.2);
  border-radius: 12px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #A5B4FC;
  
  svg {
    color: #8B5CF6;
  }
`;

const TrustBadgesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
  flex-wrap: wrap;
`;

const SearchBar = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(139, 92, 246, 0.2);
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  margin: 2rem auto;
  max-width: 600px;
  
  input {
    background: transparent;
    border: none;
    color: white;
    flex: 1;
    font-family: 'JetBrains Mono', monospace;
    
    &::placeholder {
      color: #A5B4FC;
    }
    
    &:focus {
      outline: none;
    }
  }
`;

const SkillBadge = styled.span`
  background: ${props => props.featured ? 'linear-gradient(135deg, #8B5CF6, #4F46E5)' : 'rgba(139, 92, 246, 0.1)'};
  color: ${props => props.featured ? 'white' : '#A5B4FC'};
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 500;
`;

const VerificationBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #10B981;
  font-size: 0.875rem;
  
  svg {
    width: 16px;
    height: 16px;
  }
`;

const FAQSection = styled(Section)`
  background: #0F0F1A;
`;

const FAQContainer = styled.div`
  max-width: 48rem;
  margin: 0 auto;
`;

const FAQItem = styled.div`
  border: 1px solid rgba(139, 92, 246, 0.2);
  border-radius: 12px;
  margin-bottom: 1rem;
  overflow: hidden;
  transition: all 0.3s;

  &:hover {
    border-color: #8B5CF6;
  }
`;

const FAQHeader = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: rgba(139, 92, 246, 0.05);
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: left;
  transition: all 0.3s;

  &:hover {
    background: rgba(139, 92, 246, 0.1);
  }

  svg {
    color: #8B5CF6;
    transition: transform 0.3s;
  }

  &[aria-expanded="true"] svg {
    transform: rotate(180deg);
  }
`;

const FAQContent = styled.div`
  padding: ${props => props.isOpen ? '1.5rem' : '0 1.5rem'};
  max-height: ${props => props.isOpen ? '500px' : '0'};
  opacity: ${props => props.isOpen ? '1' : '0'};
  overflow: hidden;
  transition: all 0.3s;
  color: #A5B4FC;
  line-height: 1.6;

  p {
    margin: 0;
    margin-bottom: 1rem;
  }

  ul {
    margin: 0;
    padding-left: 1.5rem;
  }

  li {
    margin-bottom: 0.5rem;
  }
`;

function SiteTest() {

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [openFAQs, setOpenFAQs] = useState({});

  const metrics = [
    { value: '5000+', label: 'Clients Vérifiés', icon: <Users size={24} /> },
    { value: '10k+', label: 'Projets Réalisés', icon: <CheckCircle2 size={24} /> },
    { value: '95%', label: 'Taux de Réussite', icon: <Award size={24} /> },
    { value: '24/7', label: 'Support Disponible', icon: <Timer size={24} /> }
  ];

  const trustIndicators = [
    { icon: <Shield size={20} />, text: 'Paiements Sécurisés' },
    { icon: <UserCheck size={20} />, text: 'Experts Vérifiés' },
    { icon: <Clock size={20} />, text: 'Garantie Satisfait ou Remboursé' }
  ];

  const methodology = [
    {
      icon: <FileCheck size={32} />,
      title: 'Publiez Votre Projet',
      description: 'Décrivez vos besoins en tests et trouvez des experts qualifiés.'
    },
    {
      icon: <Settings size={32} />,
      title: 'Examinez les Profils',
      description: 'Parcourez les profils des testeurs vérifiés et sélectionnez le meilleur candidat.'
    },
    {
      icon: <GitBranch size={32} />,
      title: 'Collaborez en Toute Sécurité',
      description: 'Travaillez avec votre expert via notre plateforme sécurisée avec paiements garantis.'
    },
    {
      icon: <LineChart size={32} />,
      title: 'Suivez les Progrès',
      description: 'Surveillez les étapes et les livrables via notre tableau de bord intuitif.'
    }
  ];

  const faqs = [
    {
        question: "Comment embaucher un expert en automatisation de tests sur votre plateforme ?",
        answer: "L'embauche est simple : 1) Publiez vos exigences de projet, 2) Examinez les propositions d'experts qualifiés, 3) Interviewez vos meilleurs choix, 4) Sélectionnez et embauchez votre expert préféré. Notre plateforme gère les paiements et la gestion de projet en toute sécurité."
    },
    {
        question: "Quels types de services d'automatisation de tests sont disponibles ?",
        answer: "Notre plateforme propose des experts en : Tests Unitaires (Jest, Mocha), Tests E2E (Cypress, Selenium), Tests API (Postman, REST Assured), Tests Mobile (Appium), Tests de Performance (JMeter, K6), et Intégration CI/CD."
    },
    {
        question: "Combien coûte l'embauche d'un freelance en automatisation de tests ?",
        answer: "Les tarifs varient selon l'expertise et la complexité du projet : Testeurs juniors (30-50€/h), Experts de niveau intermédiaire (50-80€/h), Spécialistes seniors (80-150€/h). Vous pouvez définir votre budget et recevoir des propositions dans votre fourchette."
    },
    {
        question: "Qu'est-ce qui rend vos experts en automatisation de tests fiables ?",
        answer: "Nous assurons la qualité grâce à : Des profils professionnels vérifiés, Des tests d'évaluation des compétences, Des avis et notes clients, Une vérification de l'historique de travail, Une protection des paiements sécurisée, et Une garantie satisfait ou remboursé."
    },
    {
        question: "Puis-je embaucher des experts en automatisation de tests pour des projets à long terme ?",
        answer: "Oui ! De nombreux clients embauchent nos experts pour des projets à long terme. Vous pouvez choisir entre : Projets à prix fixe, Contrats à taux horaire, Arrangements à temps partiel ou temps plein, Options d'augmentation d'équipe."
    },
    {
        question: "Que comprennent les services d'automatisation de tests ?",
        answer: "Les services comprennent généralement : Développement de la stratégie de test, Configuration du framework, Création de scripts de test, Intégration CI/CD, Exécution et reporting des tests, Documentation, Maintenance et mises à jour, Transfert de connaissances."
    },
    {
        question: "Comment évaluer un freelance en tests automatisés ?",
        answer: "Vérifiez son portfolio, demandez des exemples de projets antérieurs, et consultez les avis des clients. Un entretien technique peut également vous aider à comprendre sa compréhension des processus de test."
    },
    {
        question: "Quelles sont les compétences requises pour un spécialiste en automatisation de tests ?",
        answer: "Un spécialiste doit être à l'aise avec des langages de programmation comme JavaScript, Python ou Java, connaître des frameworks de tests comme Selenium ou Cypress, et maîtriser les concepts de CI/CD."
    },
    {
        question: "Quels outils recommandez-vous pour l'automatisation des tests ?",
        answer: "Des outils comme Selenium, Cypress, JUnit, Postman pour les tests API, et K6 pour le test de performance sont fortement recommandés pour leurs fonctionnalités robustes."
    },
    {
        question: "Comment intégrer les tests automatisés dans un pipeline CI/CD ?",
        answer: "Les tests automatisés peuvent être intégrés dans votre pipeline CI/CD en les configurant pour qu'ils s'exécutent à chaque validation ou déploiement. Cela garantit que les nouveaux changements n'introduisent pas de défauts."
    },
    {
        question: "Quelle est la durée d'un projet d'automatisation de tests ?",
        answer: "La durée dépend de la taille du projet. Un projet d'automatisation de tests pour une application simple peut prendre quelques semaines, tandis qu'un projet plus complexe peut nécessiter plusieurs mois de collaboration."
    },
    {
        question: "Comment assurez-vous la couverture de tests dans un projet ?",
        answer: "Nous suivons des métriques de couverture de code, en ciblant un minimum de 80% de couverture pour s'assurer que la majorité du code est testé. Nos experts analysent les résultats pour identifier les parties non couvertes."
    },
    {
        question: "Comment gérer les échecs de tests dans un projet ?",
        answer: "Il est important d'analyser les échecs de tests, de comprendre la cause sous-jacente, et d'ajuster le code ou le test selon les besoins. La communication avec le développeur concerné est cruciale."
    },
    {
        question: "Comment les captures d'écran et les vidéos aident-elles dans les tests automatisés ?",
        answer: "Elles permettent de visualiser les échecs lors de l'exécution des tests, facilitant le diagnostic des problèmes. Des outils comme Cypress et Selenium permettent d'enregistrer des vidéos et de prendre des captures d'écran."
    },
    {
        question: "Quel type de test est le plus important ?",
        answer: "Chaque type de test a son importance. Cependant, les tests unitaires sont fondamentaux pour la validation des composants, tandis que les tests E2E garantissent que le produit fonctionne comme une unité entièrement intégrée."
    },
    {
        question: "Comment s'assurer que les tests couvrent toutes les fonctionnalités critiques ?",
        answer: "En impliquant les parties prenantes lors de la définition des exigences et en réalisant des revues régulières de vos scénarios de test pour s’assurer que toutes les fonctionnalités critiques sont couvertes."
    },
    {
        question: "Quelle est la différence entre un test fonctionnelle et un test non fonctionnel ?",
        answer: "Les tests fonctionnels vérifient que les fonctionnalités du système fonctionnent comme prévu, tandis que les tests non fonctionnels évaluent des aspects comme la performance, la sécurité, et l'expérience utilisateur."
    },
    {
        question: "Comment puis-je améliorer la vitesse d'exécution de mes tests automatisés ?",
        answer: "Optimisez vos tests en évitant les tests redondants, en utilisant des tests parallèles, et en implémentant des outils de mise en cache. Une révision de la structure de tests peut également contribuer."
    },
    {
        question: "Quelles sont les erreurs courantes à éviter lors de l'automatisation des tests ?",
        answer: "Les erreurs courantes incluent des tests non fiables, l'absence de maintenance des scripts de test, et le manque de collaboration avec l'équipe de développement. Assurez-vous que les tests sont régulièrement mis à jour."
    },
    {
        question: "Comment évaluer un système d'automatisation de tests ?",
        answer: "Évaluez son efficacité en mesurant la couverture des tests, la réactivité aux échecs, et le retour d’expérience des utilisateurs résultant des tests. Des KPI peuvent aider à suivre ces métriques."
    },
    {
        question: "Quelles technologies modernes sont essentielles pour l'automatisation des tests ?",
        answer: "Des technologies comme Selenium, Cypress pour les tests web, Appium pour les tests mobiles, et des solutions de CI/CD comme Jenkins et GitHub Actions sont essentielles."
    },
    {
        question: "Comment recueillir des retours d'expérience des utilisateurs sur les tests effectués ?",
        answer: "Utilisez des enquêtes, des discussions ouvertes, et des groupes de discussion pour recueillir les retours des utilisateurs. Cela peut également être renforcé par des analyses de données d’utilisation post-lancement."
    },
    {
        question: "Comment intégrer les feedbacks utilisateurs dans le processus de test ?",
        answer: "Analysez les retours utilisateurs, priorisez les retours critiques, et intégrez-les dans le développement de vos nouveaux tests ou ajustez vos tests existants en fonction des retours." 
    }
];

  const handleModalRegister = () => {
    setOpenModalRegister(true);
  };

  const handleCloseModalRegister = () => {
    setOpenModalRegister(false);
  };

  const handelSearchProjects = () => {
    window.location.href = `${process.env.REACT_APP_FRONTED_URL}/search/projects/category/SYSTEM_TESTER`;
  };

  const services = [
    {
      icon: <Code2 size={32} />,
      title: 'Freelances en Tests Unitaires',
      description: 'Trouvez des freelances qualifiés spécialisés dans les tests unitaires avec Jest, Mocha et Vitest.',
      avgRate: '45-75€/h',
      topSkills: ['Jest', 'Mocha', 'Vitest'],
      activeFreelancers: 234
    },
    {
      icon: <Bug size={32} />,
      title: 'Experts en Tests E2E',
      description: 'Connectez-vous avec des freelances et des agences expérimentés en tests E2E utilisant Cypress, Playwright et Selenium.',
      avgRate: '50-90€/h',
      topSkills: ['Cypress', 'Playwright', 'Selenium'],
      activeFreelancers: 189
    },
    {
      icon: <Workflow size={32} />,
      title: 'Spécialistes CI/CD',
      description: 'Embauchez des professionnels qui excellent dans l\'intégration des tests dans les pipelines CI/CD pour une assurance qualité continue.',
      avgRate: '60-100€/h',
      topSkills: ['Jenkins', 'GitHub Actions', 'GitLab CI'],
      activeFreelancers: 156
    }
  ];

  const projects = [
    {
      title: 'Automatisation E-commerce',
      company: 'Freelance de France',
      location: 'À distance',
      type: 'Tests E2E',
      metrics: '99.9% Couverture',
      description: "Un freelance a implémenté avec succès une suite complète de tests E2E pour une plateforme e-commerce majeure.",
      tech: ['Cypress', 'GitHub Actions', 'Jest', 'TestCafe', 'mission freelance wordpress']
    },
    {
      title: 'Tests Microservices',
      company: 'Agence de Test',
      location: 'Europe',
      type: 'Intégration',
      metrics: '2500+ Tests',
      description: "Une équipe d'agence a livré des tests d'intégration complets pour une architecture microservices complexe.",
      tech: ['Jest', 'Supertest', 'Docker', 'K6', 'mission freelance informatique']
    },
    {
      title: "Tests d'Applications Mobiles",
      company: 'Équipe Freelance',
      location: 'International',
      type: 'Tests Mobile',
      metrics: '95% Couverture',
      description: "Une équipe de freelances a créé des tests automatisés pour les applications iOS et Android avec des rapports détaillés.",
      tech: ['Appium', 'Detox', 'XCTest', 'Espresso', 'mission freelance mobile']
    },
    {
      title: 'Tests de Performance',
      company: 'Freelance Senior',
      location: 'Europe',
      type: 'Performance',
      metrics: '10k Tests/Jour',
      description: "Un expert freelance a implémenté des tests de charge et de performance pour des applications haute disponibilité.",
      tech: ['K6', 'JMeter', 'Gatling', 'Artillery', 'mission freelance marketing digital']
    },
    {
      title: 'Suite de Tests API',
      company: 'Consultant en Tests',
      location: 'France',
      type: 'API',
      metrics: '100% Couverture',
      description: "Un consultant expérimenté a livré une automatisation complète des tests API avec validation des contrats.",
      tech: ['Postman', 'Newman', 'Pact', 'Dredd', 'mission freelance dev']
    },
    {
      title: 'Tests de Sécurité pour Applications Web',
      company: 'Expert en Sécurité',
      location: 'Remote',
      type: 'Sécurité',
      metrics: '95% Réduction d\'Incidents',
      description: "Tests de sécurité effectués pour une application web avec OWASP ZAP et Burp Suite.",
      tech: ['OWASP ZAP', 'Burp Suite', 'Pentest', 'mission freelance iti']
    },
    {
      title: 'Tests de Charge pour E-commerce',
      company: 'Agence Marketing',
      location: 'Europe',
      type: 'Performances',
      metrics: '5000 Utilisateurs Simultanés',
      description: "Implémentation de tests de charge pour évaluer la robustesse d'une plateforme e-commerce.",
      tech: ['Apache JMeter', 'Load Testing', 'Performance Monitoring', 'mission freelance marketing']
    },
    {
      title: 'Développement d\'Outils pour Tests Automatisés',
      company: 'Tech Solutions',
      location: 'À distance',
      type: 'Automatisation',
      metrics: '100% des Scénarios Couvert',
      description: "Création d'outils pour automatiser les tests avec CI/CD en intégration avec Jenkins.",
      tech: ['Jenkins', 'Selenium', 'Automatisation', 'mission freelance dev']
    },
    {
      title: 'Intégration de Tests A/B',
      company: 'Growth Team',
      location: 'Remote',
      type: 'Tests A/B',
      metrics: '200% Amélioration des Conversions',
      description: "Développement d'un programme de tests A/B pour optimiser les taux de conversion.",
      tech: ['A/B Testing', 'Google Optimize', 'UX/UI', 'mission freelance marketing digital']
    },
    {
      title: 'Tests de Compatibilité Multi-navigateurs',
      company: 'Web Ventures',
      location: 'International',
      type: 'Compatibilité',
      metrics: '99% Compatibilité',
      description: "Réalisation de tests de compatibilité sur divers navigateurs et appareils pour une application web.",
      tech: ['Selenium', 'BrowserStack', 'Cross Browser Testing', 'mission freelance dev']
    },
    {
      title: 'Mise en Place de Webhooks',
      company: 'API Innovators',
      location: 'Remote - Européen',
      type: 'Webhooks',
      metrics: '100% Réponse en Temps Réel',
      description: "Création d’un système de webhooks pour les intégrations tierces.",
      tech: ['Webhook', 'REST API', 'Node.js', 'mission freelance informatique']
    },
    {
      title: 'Système de Notifications',
      company: 'Messaging Solutions',
      location: 'À distance',
      type: 'Notifications',
      metrics: '95% de Livraison Réussie',
      description: "Développement d'un système de notifications pour une application mobile.",
      tech: ['Firebase', 'Web Push', 'iOS Notifications', 'mission freelance mobile']
    },
    {
      title: 'Développement d\'Applications de Chat',
      company: 'Chat App Inc.',
      location: 'Remote',
      type: 'Chat',
      metrics: '5000 Utilisateurs Actifs',
      description: "Création d'une application de chat en temps réel avec WebSocket.",
      tech: ['Node.js', 'WebSocket', 'React', 'mission freelance mobile app']
    },
    {
      title: 'Consultant en Performance Web',
      company: 'Web Analytics',
      location: 'France',
      type: 'Consulting',
      metrics: 'Analyse de 30+ Sites',
      description: "Audit des performances et recommandations d'optimisation pour augmenter la vitesse de chargement.",
      tech: ['Google PageSpeed', 'GTmetrix', 'Performance Analysis', 'mission freelance web']
    },
    {
      title: 'Optimisation des Test Coverage',
      company: 'QA Experts',
      location: 'Remote',
      type: 'Couverture',
      metrics: '80%+ Couverture de Tests',
      description: "Amélioration de la couverture de tests unitaires et intégration avec des rapports automatisés.",
      tech: ['Jest', 'Mocha', 'Chai', 'mission freelance developer']
    },
    {
      title: 'Développement d\'Applications de Suivi de Compte',
      company: 'Finance App',
      location: 'Remote - International',
      type: 'Suivi',
      metrics: '10K+ Téléchargements',
      description: "Création d'une application mobile pour la gestion et le suivi des finances personnelles.",
      tech: ['Flutter', 'Firebase', 'API', 'freelancer mobile app']
    },
    {
      title: 'Développement de Système de Gestion de Projet',
      company: 'Project Management Agency',
      location: 'Remote - France',
      type: 'Gestion de Projet',
      metrics: '350+ Projets Gérés',
      description: "Développement d'un outil de gestion de projet avec intégration de camembert et Gantt.",
      tech: ['React', 'Node.js', 'MongoDB', 'mission freelance web']
    },
    {
      title: 'Création d\'un Gestionnaire de Tâches',
      company: 'Task Manager Co.',
      location: 'Remote - Européen',
      type: 'Gestion de Tâches',
      metrics: 'Utilisateurs : 2K+',
      description: "Développement d'une application pour gérer les tâches personnelles et de travail.",
      tech: ['React Native', 'Node.js', 'SQLite', 'freelance mobile developer']
    },
    {
      title: 'Optimisation de l\'Interaction Utilisateur',
      company: 'User Experience Agency',
      location: 'Remote',
      type: 'Consulting',
      metrics: 'Amélioration de l\'UX de 30%',
      description: "Audits UX pour améliorer l'interaction utilisateur et la satisfaction.",
      tech: ['User Testing', 'Wireframing', 'Prototyping', 'freelance mobile UX']
    },
    {
      title: 'Développement d\'un Outil de Planification',
      company: 'Planner App',
      location: 'Remote - France',
      type: 'Planification',
      metrics: '500+ Utilisateurs Actifs',
      description: "Développement d'un outil de planning partagé pour les équipes.",
      tech: ['React', 'Node.js', 'Firebase', 'freelancer mobile app']
    }
];

  const toggleFAQ = (index) => {
    setOpenFAQs(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };


  return (
    <>
    <Helmet>
        <title>Plateforme Freelance Web | Recruter des Développeurs Freelance & Agences de Développement</title>
        <meta name="description" content="Découvrez les meilleures plateformes pour recruter des freelances web, des développeurs, et des agences spécialisées dans la création de sites internet et missions freelance." />
        <meta
            name="keywords"
            content="mission freelance telecom, mission freelance web, mission freelance webdesigner, mission freelance wordpress, mission independant freelance, mission ingenieur freelance, mission télétravail freelance, mission webdesigner freelance, missions de freelance, offre de mission freelance informatique, offre mission freelance informatique, offres missions freelance, offres de missions freelance"
        />
        <link rel="canonical" href="https://itgalaxy.io" />
        <meta property="og:title" content="Plateforme Freelance Web | Recrutement de Développeurs et Agences" />
        <meta property="og:description" content="Trouvez des freelances qualifiés et des agences pour la création de sites internet, le développement web, et des missions freelances." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://itgalaxy.io" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:site_name" content="ItGalaxy.io" />
        <link rel="alternate" hreflang="fr" href="https://itgalaxy.io" />
        <link rel="alternate" hreflang="en" href="https://itgalaxy.io" />
        <script type="application/ld+json">
            {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "Service",
                "name": "Plateforme Freelance Web",
                "provider": {
                    "@type": "Organization",
                    "name": "ItGalaxy",
                    "address": {
                        "@type": "PostalAddress",
                        "addressLocality": "Paris",
                        "addressRegion": "Île-de-France",
                        "addressCountry": "FR"
                    }
                },
                "description": "Recrutement de freelances web et d'agences de développement pour des projets de sites internet et missions freelance.",
                "areaServed": ["France", "Paris", "Lyon", "Marseille"],
                "serviceType": ["Recrutement Freelance", "Développement Web", "Création de Sites Internet"],
            })}
        </script>
    </Helmet>
      <Header />
      <GlobalStyle />
      <MainContainer>
        <HeroSection>
          <HeroContent>
            <Logo>
              <TestTube2 />
            </Logo>
            <HeroTitle>Missions sur l'Automatisation des Tests</HeroTitle>
            <HeroSubtitle>
              Notre plateforme vous connecte avec des clients vérifiés du monde entier.
            </HeroSubtitle>
            <TrustBadgesContainer>
              {trustIndicators.map((indicator, index) => (
                <TrustBadge key={index}>
                  {indicator.icon}
                  {indicator.text}
                </TrustBadge>
              ))}
            </TrustBadgesContainer>
          </HeroContent>
        </HeroSection>

        <Section>
          <SectionContent>
            <SectionTitle>
              <BarChart3 />
              Projets en Vedette
            </SectionTitle>
            <SectionSubtitle>
              Découvrez les projets de test réussis réalisés par nos freelances et agences
            </SectionSubtitle>
            <ProjectsGrid>
              {projects.map((project, index) => (
                <ProjectCard key={index}>
                  <ProjectHeader>
                    <ProjectInfo>
                      <ProjectTitle>{project.title}</ProjectTitle>
                      <ProjectCompany>
                        {project.company}
                        <VerificationBadge>
                          <CheckCircle2 size={16} />
                          Vérifié
                        </VerificationBadge>
                      </ProjectCompany>
                      <ProjectMeta>
                        <span>{project.location}</span>
                        <span>{project.type}</span>
                      </ProjectMeta>
                    </ProjectInfo>
                    <ProjectMetrics>{project.metrics}</ProjectMetrics>
                  </ProjectHeader>
                  <ProjectDescription>{project.description}</ProjectDescription>
                  <TechStack>
                    {project.tech.map((tech, techIndex) => (
                      <TechTag key={techIndex}>{tech}</TechTag>
                    ))}
                  </TechStack>
                  <Button onClick={handleModalRegister} style={{ marginTop: '1.5rem', width: '100%' }}>
                    Postulez pour ce projet
                    <ChevronRight size={20} />
                  </Button>
                </ProjectCard>
              ))}
            </ProjectsGrid>
            <ButtonContainer>
              <SeeMoreButton onClick={() => handleModalRegister()}>
                Explorer Plus de Projets
                <ArrowDown size={20} />
              </SeeMoreButton>
            </ButtonContainer>
          </SectionContent>
        </Section>

        <MethodologySection>
          <SectionContent>
            <SectionTitle>
              <Workflow />
              Comment ça Marche
            </SectionTitle>
            <SectionSubtitle>
              Un processus simple pour trouver et collaborer avec des experts en tests
            </SectionSubtitle>
            <MethodologyGrid>
              {methodology.map((item, index) => (
                <MethodologyCard key={index}>
                  <MethodologyIcon>{item.icon}</MethodologyIcon>
                  <MethodologyTitle>{item.title}</MethodologyTitle>
                  <MethodologyDescription>{item.description}</MethodologyDescription>
                </MethodologyCard>
              ))}
            </MethodologyGrid>
          </SectionContent>
        </MethodologySection>

        <Section>
          <SectionContent>
            <SectionTitle>
              <CheckCircle2 />
              Services Disponibles
            </SectionTitle>
            <SectionSubtitle>
              Parcourez notre place de marché de professionnels proposant divers services d'automatisation
            </SectionSubtitle>
            <ServicesGrid>
              {services.map((service, index) => (
                <ServiceCard key={index}>
                  <ServiceIcon>{service.icon}</ServiceIcon>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDescription>{service.description}</ServiceDescription>
                  <ServiceStats>
                    <StatItem>
                      <div className="value">{service.avgRate}</div>
                      <div className="label">Taux Moyen</div>
                    </StatItem>
                    <StatItem>
                      <div className="value">{service.activeFreelancers}</div>
                      <div className="label">Experts Actifs</div>
                    </StatItem>
                  </ServiceStats>
                  <SkillsContainer>
                    {service.topSkills.map((skill, idx) => (
                      <SkillBadge key={idx} featured={idx === 0}>{skill}</SkillBadge>
                    ))}
                  </SkillsContainer>
                </ServiceCard>
              ))}
            </ServicesGrid>
          </SectionContent>
        </Section>

         <FAQSection>
          <SectionContent>
            <SectionTitle>
              <HelpCircle />
              Questions Fréquentes
            </SectionTitle>
            <SectionSubtitle>
              Tout ce que vous devez savoir sur l'embauche d'experts en automatisation de tests
            </SectionSubtitle>
            <FAQContainer>
              {faqs.map((faq, index) => (
                <FAQItem key={index}>
                  <FAQHeader
                    onClick={() => toggleFAQ(index)}
                    aria-expanded={openFAQs[index]}
                    aria-controls={`faq-content-${index}`}
                  >
                    {faq.question}
                    {openFAQs[index] ? <MinusCircle size={20} /> : <PlusCircle size={20} />}
                  </FAQHeader>
                  <FAQContent
                    id={`faq-content-${index}`}
                    isOpen={openFAQs[index]}
                    role="region"
                    aria-labelledby={`faq-header-${index}`}
                  >
                    {faq.answer}
                  </FAQContent>
                </FAQItem>
              ))}
            </FAQContainer>
          </SectionContent>
        </FAQSection>
      </MainContainer>
      <Offers />
      <FooterHome page={"design"} />
      {openModalRegister && (
        <Register
          openModalRegister={openModalRegister}
          setOpenModalRegister={setOpenModalRegister}
          handleModalRegister={handleCloseModalRegister}
          switchBetweenModals={false}
          proxy={"marketplace"}
        />)}

    </>
  );
}

export default SiteTest;